<!-- 申请理赔 -->
<template>
  <div class="claim-container">
    <van-pull-refresh style="min-height: 100vh" v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        finished-text="没有更多了"
        @load="onLoad"
        :immediate-check="false"
      >
        <template v-if="orderList.length > 0">
          <div class="order-item" v-for="(item, index) in orderList" :key="index">
            <div @click="toDetail(item.id)">
              <p class="title">
                <span class="title-txt van-ellipsis">{{ item.productName }}</span>
                <!-- guarantee  surrender-->
                <span v-if="item.newStatus == 3" class="status-txt guarantee-color">保障中</span>
              </p>
              <p class="time">投保时间：{{ item.insuredTime }}</p>
              <div class="content">
                <p class="name">
                  <span class="label">投/被保人：</span>
                  <span class="value">{{ item.appntName }}/{{ item.insuredName }}</span>
                </p>
                <p class="ins-period" v-if="item.insStartTime && item.insEndTime">
                  <span class="label"> 保险期间：</span>
                  <span class="value">{{ item.insStartTime ? item.insStartTime : "--" }} 至 {{ item.insEndTime ? item.insEndTime : "--" }}</span>
                </p>
                <p class="premium">
                  <span class="label"> 保费：</span>
                  <span class="value">{{ item.premium }}元 </span>
                </p>

                <img v-if="item.newStatus == 3" class="seal" src="../../assets/image/guaranteeing-seal.png" alt="" />
              </div>
            </div>
            <div class="btn-group">
              <!-- .orange-btn -->
              <!-- 保障中 -->
              <template v-if="item.newStatus == 3">
                <div class="right">
                  <button class="btn" @click.stop="showClaimGuide(item, item.product.guideText)">申请理赔</button>
                </div>
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <van-empty></van-empty>
        </template>
      </van-list>
    </van-pull-refresh>
    <van-popup closeable v-model="claimGuideVisible" title="理赔指引" round position="bottom" class="guide-popup">
      <div class="popup-title">理赔指引</div>
      <p class="title" v-if="claimGuide.firstStep">1.报案</p>
      <p class="desc" v-if="claimGuide.firstStep" v-html="claimGuide.firstStep"></p>
      <p class="title" v-if="claimGuide.secondStep">2.准备材料</p>
      <p class="desc" v-if="claimGuide.secondStep" v-html="claimGuide.secondStep"></p>
      <p class="title" v-if="claimGuide.thirdStep">3.保险公司核准</p>
      <p class="desc" v-if="claimGuide.thirdStep" v-html="claimGuide.thirdStep"></p>
      <p class="title" v-if="claimGuide.fourthStep">4.出具结果</p>
      <p class="desc" v-if="claimGuide.fourthStep" v-html="claimGuide.fourthStep"></p>
    </van-popup>
  </div>
</template>

<script>
import { getOrderList } from "../../api/api"
import loadCustomerService from "@/mixins/loadCustomerService"
import { getSession } from "../../util/util"
export default {
  mixins: [loadCustomerService],
  data() {
    return {
      activeTab: 3,
      refreshing: false,
      loading: false,
      error: false,
      finished: false,
      orderList: [],
      page: 1,
      perPage: 3,
      claimGuideVisible: false,
      claimGuide: {
        firstStep: "",
        secondStep: "",
        thirdStep: "",
        fourthStep: "",
      },
      csInfo: [],
    }
  },
  created() {
    this.onLoad()
  },
  methods: {
    async onLoad() {
      if (this.refreshing) {
        this.orderList = []
        this.refreshing = false
      }

      let res = await getOrderList({
        page: this.page,
        perPage: this.perPage,
        status: this.activeTab,
      })

      this.page++
      this.loading = false
      this.orderList = this.orderList.concat(res.data.list)
      if (this.orderList.length > 0) {
        this.orderList.map((item) => {
          if (!item.product.guideText) {
            item.product.guideText = {
              claim: {
                firstStep: "",
                secondStep: "",
                thirdStep: "",
                fourthStep: "",
              },
              visit: [],
              surrender: [],
              preservation: [],
            }
          }
        })
      }
      if (this.orderList.length >= res.data.totalNum) {
        this.finished = true
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      this.page = 1
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    toDetail(id) {
      this.$router.push(`/orderDetail?id=${id}&source=${this.$route.path}`)
    },
    serviceDialog(info, type) {
      let message = ""
      switch (type) {
        case "claim":
          message = "您好，暂不支持在线理赔，请联系客服处理。"
          break
        case "visit":
          message = "您好，暂不支持在线回访，请联系客服处理。 "
          break
        case "preservation":
          message = "您好，暂不支持在线变更信息，请联系客服处理。"
          break
        case "surrender":
          message = "您好，暂不支持在线退保，请联系客服处理。 "
          break
        case "url":
          message = "您好，该链接请咨询客服~ "
          break
      }
      this.$dialog
        .confirm({
          message,
          confirmButtonColor: "#fe8a3d",
          confirmButtonText: "立即咨询",
          cancelButtonText: "关闭",
        })
        .then(() => {
          try {
            console.log("info: ", info)
            const userInfo = getSession("policyToolsInfo") || {}
            const { id, name, mobile } = userInfo
            // on close
            this.csInfo = [
              {
                key: "uid",
                label: "用户id",
                value: id,
              },
              {
                key: "uid",
                label: "用户名称",
                value: name,
              },
              {
                key: "mobile",
                label: "用户电话",
                value: mobile,
              },
              {
                key: "referringPage",
                label: "来源",
                value: "保单大师-我的订单-订单列表",
              },
              {
                key: "productName",
                label: "商品名称",
                value: info.productName,
              },
              {
                key: "orderSn",
                label: "订单号",
                value: info.orderSn,
              },
              {
                key: "insuranceSn",
                label: "保单号",
                value: info.insuranceSn ? info.insuranceSn : "未知",
              },
              {
                key: "applicantInsured",
                label: "投/被保人",
                value: `${info.appntName}${"/" + info.insuredName}`,
              },
              {
                key: "premium",
                label: "保费",
                value: info.premium,
              },
              {
                key: "status",
                label: "订单状态",
                value: info.statusName,
              },
              {
                key: "visitStatus",
                label: "回访状态",
                value: info.visitStatus,
              },
              {
                key: "visitType",
                label: "回访类型",
                value: info.visitType,
              },
            ]
            this.showChat()
          } catch (error) {
            console.log("error: ", error)
          }
        })
        .catch(() => {
          // on cancel
        })
    },
    showClaimGuide(info, data) {
      console.log("data: ", data)
      this.serviceDialog(info, "claim")
      if (!data.claim.firstStep) {
        this.serviceDialog(info, "claim")
        return
      }
      this.claimGuide = data.claim
      this.claimGuideVisible = true
    },
  },
}
</script>
<style lang="less" scoped>
.claim-container {
  background-color: #fbfbfb;
  width: 100%;
  height: 100%;

  .surrender-color {
    color: #98999a;
  }
  .guarantee-color {
    color: #5ba5fd;
  }
  .orange-color {
    color: #fe8a3d;
  }
  /deep/.van-pull-refresh {
    background-color: #fbfbfb;
    padding: 0 24px;
    height: calc(100vh - 92px) !important;
    overflow: auto !important;
  }
  /deep/ .van-list {
    height: auto !important;
  }
  .order-item {
    width: 702px;
    margin-top: 30px;
    padding: 30px 24px;
    font-size: 30px;
    background-color: #fff;
    box-shadow: 0px 4px 77px 0px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-txt {
        width: 374px;
        height: 48px;
        font-size: 34px;
        font-weight: 500;
        color: #333333;
        line-height: 48px;
      }
      .status-txt {
        height: 40px;
        font-size: 28px;

        line-height: 40px;
      }
    }
    .time {
      height: 37px;
      margin-top: 4px;
      font-size: 26px;
      color: #777777;
      line-height: 37px;
    }
    .content {
      position: relative;
      overflow: hidden;
      width: 654px;
      margin-top: 20px;
      padding: 24px;
      background-color: #fafafa;
      border-radius: 20px;

      .label {
        color: #777;
      }
      .value {
        color: #333;
      }
      p {
        height: 42px;
        line-height: 42px;
        &:nth-child(n + 2) {
          margin-top: 12px;
        }
      }
      .seal {
        position: absolute;
        top: -14px;
        right: -14px;
        width: 100px;
        height: 100px;
      }
      .gray-gradient {
        color: #777;
        background: linear-gradient(264deg, rgba(247, 247, 247, 0) 0%, #ededed 100%);
      }
      .orange-gradient {
        color: #fe8a3d;
        background: linear-gradient(264deg, rgba(255, 247, 242, 0) 0%, #fff3e8 100%);
      }
      .underwriting-desc {
        display: flex;
        align-items: center;
        width: 600px;
        height: 60px;
        margin-top: 20px;
        padding: 10px 0 10px 16px;
        font-size: 28px;
        border-radius: 10px;
        p {
          width: 520px;
        }
      }
    }
    .btn-group {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .more {
        color: #777777;
        height: 37px;
        font-size: 26px;
        color: #777777;
        line-height: 37px;
      }
      .right {
        margin-left: auto;
        display: flex;
        align-items: center;
      }
      .btn {
        width: 168px;
        height: 68px;
        margin-left: 16px;
        border-radius: 34px;
        border: 1px solid #cecfd0;
        text-align: center;
        line-height: 68px;
        font-size: 26px;
        color: #333333;
        background-color: #fff;
      }
      .orange-btn {
        border-color: #fe8a3d;
        color: #fe8a3d;
      }
    }
  }
}
</style>
